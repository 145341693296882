import React from "react";

const PrivacyPolicyData = () => {
  return (
    <section className="cta-one">
      <p data-aos="fade-right" data-aos-delay="400" align="left">
        Versión 1.0 vigente desde el 10 de enero de 2022
        <br/><br/>
        Por favor, lee detenidamente esta política de privacidad para usuarios de la aplicación móvil oficial HELZY (en adelante, “Aplicación” o “HELZY”), propiedad de ELSETIC SL. (en adelante, "ELSETIC" o "TITULAR"), donde podrás encontrar toda la información sobre los datos que recopilamos acerca de ti, cómo lo usamos y qué control tienes sobre los mismos.
        <br/><br/>
        <b>AVISO IMPORTANTE</b>: La persona que utiliza la aplicación (en adelante, "USUARIO" o "USUARIOS") queda informado de que la utilización de la Aplicación <b>NO CONSTITUYE EN NINGÚN CASO UN SERVICIO DE DIAGNÓSTICO MÉDICO, NI FORMA PARTE DE UN TIPO DE ATENCIÓN DE URGENCIAS O DE PRESCRIPCIÓN DE TRATAMIENTOS FARMACOLÓGICOS</b>. Se advierte y pone en conocimiento del USUARIO, que la utilización de la Aplicación no puede en ningún caso sustituir la consulta personal frente a un profesional médico debidamente cualificado.
      </p>
      <br/>
      <h3 data-aos="fade-left" data-aos-delay="200">
        1. ¿Quién es responsable del tratamiento de tus datos como usuario de HELZY?
      </h3>
      <p data-aos="fade-right" data-aos-delay="400" align="left">
        El responsable del tratamiento de tus datos como usuario de HELZY es:<br/><br/>
        — <b>Nombre</b>: ELSETIC, S.L. <br/>
        — <b>CIF</b>: B01794015 <br/>
        — <b>Dirección</b>: Calle Nord 49, Casa 29, 08950, Esplugues de Llobregat, Barcelona. <br/>
        — <b>Delegado de Protección de Datos</b>: Comité DPD de ELSETIC. SL. <br/>
        — <b>Dirección DPD</b>: Calle Nord 49, Casa 29, 08950, Esplugues de Llobregat, Barcelona. <br/>
      </p>
      <br/>
      <h3 data-aos="fade-left" data-aos-delay="200">
        2. ¿Qué datos tratamos sobre ti?
      </h3>
      <p data-aos="fade-right" data-aos-delay="400" align="left">
        A través de la aplicación tratamos diferentes datos en función del modelo de uso que se esté utilizando en HELZY.
      </p>
      <p data-aos="fade-right" data-aos-delay="400" align="left">
          Se deben diferenciar 2 tipos de datos tratados en Helzy:<br/>
          - Datos para idetentificar al USUARIO<br/>
          - Datos utilizados en el envío de alertas
      </p>
      <br/>
      <h4 data-aos="fade-left" data-aos-delay="200">
        2.1 Datos para identificar al USUARIO
      </h4>
      <p data-aos="fade-right" data-aos-delay="400" align="left">
        La primera vez que se accede a la Aplicación se debe informar el Nombre y el Teléfono para poder autenticar correctamente al USUARIO y evitar suplantaciones de identidad.<br/>
        Estos datos se almacenan para poder enviarlos a la hora de enviar alertas a los contactos de emergencia que el USUARIO haya configurado.
        <br/><br/>
        Adicionalmente, HELZY, necesita de ciertos permisos y datos en el dispositivo para poder funcionar correctamente:<br/>
        — Primero, el uso de la Geolocalización que se utilizará para poder enviar la posición GPS de la persona en caso de emitir una alerta pulsando en el botón de Solicitar Ayuda<br/>
        — Segundo, permiso de acceso a los contactos del teléfono para añadir los contactos de emergencia a los que se notificarán las alertas.<br/>
        <br/>
      </p>
      <br/>
      <h4 data-aos="fade-left" data-aos-delay="200">
        2.2 Datos utilizados en el envío de alertas
      </h4>
      <p data-aos="fade-right" data-aos-delay="400" align="left">
          Una vez se accede a la Aplicación, en caso de enviarse una alerta a los contactos, en ésta se envía información sobre la causa de la propia alerta. Estos datos se almacenarán en los servidores de HELZY para poder sincronizar correctamente las alertas con los contactos configurados.<br/>
          Los datos que se almacenan relativos a las alertas, son los siguientes:<br/>
          — Nombre (emisor alerta)<br/>
          — Teléfono (emisor alerta)<br/>
          — Geolocalización (emisor) en caso de permitir el acceso a la posición GPS. Si no se conceden permisos a la aplicación, no se enviarán datos relativos a la ubicación.<br/>
          — Hora exacta de la alerta.<br/>
          <br/>
          Adicionalmente, en el <b>Plan Monitorización</b> la información obtenida de la pulsera (que contiene la medición de las constantes vitales), se enviará de forma efímera (temporal), únicamente para los mensajes de las alertas generadas y <b>no se almacenarán</b> en los servidores HELZY.<br/>
          En el momento en que el contacto de emergencia reciba la alerta y la lea, esta información desaparecerá, no pudiendo acceder a ella de nuevo de ninguna forma.
      </p>

      <p data-aos="fade-right" data-aos-delay="400" align="left">
        La legislación aplicable se enumera a continuación:
        <br/>
        — Reglamento (UE) 2016/679, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos y por el que se deroga la Directiva 95/46/CE (Reglamento General de Protección de Datos).<br/>
        — Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales.<br/>
      </p>
      <br/>
      <h3 data-aos="fade-left" data-aos-delay="200">
        3. ¿Cómo obtenemos y de dónde proceden tus datos?
      </h3>
      <p data-aos="fade-right" data-aos-delay="400" align="left">
        Principalmente obtenemos tus datos directamente de ti y de tu dispositivo:
        <br/><br/>
        — Nos los proporcionas tú directamente (p.ej. Nombre y Teléfono en la Aplicación o cuando contactas con nosotros). Recuerda que los datos que nos proporciones directamente deberán ser siempre reales, veraces y estar actualizados. Como USUARIO tendrás la obligación de actualizar o rectificar estos datos.<br/>
        — Los obtenidos cuando usas la Aplicación o los obtenidos de tu dispositivo (p.ej. las alertas emitidas desde la aplicación).
        <br/><br/>
        Recuerda que puedes revisar y gestionar los permisos que otorgas a HELZY para obtener datos de tu dispositivo a través de las opciones disponibles en tu terminal, así como a través del gestor de consentimientos para la gestión de cookies a tu disposición en la página web.
      </p>
      <br/>
      <h3 data-aos="fade-left" data-aos-delay="200">
        4. ¿Para qué y por qué utilizamos tus datos?
      </h3>
      <p data-aos="fade-right" data-aos-delay="400" align="left">
        La información y datos recogidos por HELZY, se utilizarán con fines estrictamente de identificación y de comunicación a tus contactos configurados en la aplicación.<br/>
        <br/>
        A tal efecto, utilizamos tus datos para prestarte el servicio de HELZY y para que puedas hacer uso de sus funcionalidades de acuerdo con sus <a href="/terms-of-use" target="_blank" rel="noreferrer">Condiciones de Uso</a>.<br/>
        ELSETIC no hará uso de estos datos de ninguna forma que no sea el proporcionar la funcionalidad de la propia Aplicación o para facilitar la resolución de incidencias que pueda tener el USUARIO.
      </p>
      <br/>
      <h3 data-aos="fade-left" data-aos-delay="200">
        5. ¿Durante cuánto tiempo conservamos tus datos?
      </h3>
      <p data-aos="fade-right" data-aos-delay="400" align="left">
        Los datos que se almacenan en los servidores de HELZY, son los siguientes:<br/>
          — Listado de alertas generadas por el usuario y que sirven para sincronizar con los contactos configurados. Dado que esta información se envía a tus contactos de emergencia, son ellos los que la reciben y mantienen, pudiendo borrarlas en cualquier momento desde la aplicación.<br/>
          — Nombre y Teléfono que permite identificar al usuario. Estos datos, por el momento, no pueden ser borrados automáticamente, y para borrarlos, deberás contactar <a href="mailto:helzy@elsetic.com">helzy@elsetic.com</a>
          <br/><br/>
        En el momento en que desinstales la aplicación, tus datos no serán eliminados de forma automática, por lo que si requieres de su eliminación deberás ponerte en contacto con ELSETIC a través del siguiente correo: <a href="mailto:helzy@elsetic.com">helzy@elsetic.com</a><br/><br/>

        Dado que las alertas generadas, son emitidas por el propio USUARIO, estarán almacenadas indefinidamente, hasta que el USUARIO que recibe la alerta las elimine. En cuanto a la información de identificación de usuario, se almacenará de forma indefinida hasta que el USUARIO exprese explicitamente que desea eliminar su cuenta de usuario, enviando un correo a <a href="mailto:helzy@elsetic.com">helzy@elsetic.com</a>
      </p>
      <br/>
      <h3 data-aos="fade-left" data-aos-delay="200">
        6. ¿Quién tiene acceso a tus datos?
      </h3>
      <p data-aos="fade-right" data-aos-delay="400" align="left">
        - Listado de Alertas: Dado que las alertas se envían a los contactos que el USUARIO haya configurado en la aplicación, tendrán acceso al listado de las alertas generadas.<br/>
        - Datos de identificación: Únicamente se tiene acceso desde ELSETIC, y siempre con fines de resolución de incidencias, no utilizando estos datos para ningún objetivo adicional.
      </p>
      <br/>
      <h3 data-aos="fade-left" data-aos-delay="200">
        7. ¿Cuáles son tus derechos y cómo puedes controlar tus datos?
      </h3>
      <p data-aos="fade-right" data-aos-delay="400" align="left">
        La normativa te otorga una serie de derechos en relación a los datos e información que tratamos sobre ti. Concretamente, los derechos de acceso, rectificación, supresión, limitación, oposición y portabilidad.
        Puedes consultar el alcance y detalle completo de estos derechos en la página web de la Agencia Española de Protección de Datos (AEPD).
      </p>
      <br/>
      <p data-aos="fade-right" data-aos-delay="400" align="left">
        Aparte de todo lo anterior, tenemos obligación de indicarte que te asiste en todo momento el derecho para presentar una reclamación ante la Agencia Española de Protección de Datos.
      </p>
      <p data-aos="fade-right" data-aos-delay="400" align="left">
          Si en cualquier momento quieres ejercer tus derechos sobre tus datos, sólo tienes que ponerte en contacto con ELSETIC a través de <a href="mailto:helzy@elsetic.com">helzy@elsetic.com</a> e indicarnos qué acción quieres que realicemos con tus datos (modificación, eliminación, etc.)
      </p>
      <br/>
      <h3 data-aos="fade-left" data-aos-delay="200">
        8. ¿Cómo protegemos tus datos de usuario?
      </h3>
      <p data-aos="fade-right" data-aos-delay="400" align="left">
        ELSETIC garantiza la seguridad, el secreto y la confidencialidad de tus datos (a excepción de lo expuesto en el punto 6.), comunicaciones e información personal y ha adoptado las más exigentes y robustas medidas de seguridad y medios técnicos para evitar su pérdida, mal uso o su acceso sin tu autorización.
        <br/><br/>
        Además, nos comprometemos a actuar con rapidez y responsabilidad en el caso de que la seguridad de tus datos pueda estar en peligro, y a informarte si fuese relevante.
      </p>
      <br/>
      <h3 data-aos="fade-left" data-aos-delay="200">
        9. ¿Qué debes tener especialmente en cuenta al utilizar HELZY?
      </h3>
      <p data-aos="fade-right" data-aos-delay="400" align="left">
        Has de tener en cuenta determinados aspectos relativos utilización de Aplicación, la calidad de los datos que nos proporcionas, así como la baja de tu cuenta de usuario.
        <br/><br/>
        — <b>Calidad de los datos que nos proporcionas</b>: La información que nos facilites en el uso de los servicios de la Aplicación deberá de ser siempre real, veraz y estar actualizada.<br/>
        — <b>Baja de tu cuenta de usuario</b>: en caso de requerir la eliminación de tu cuenta, deberás ponerte en contacto con ELSETIC a través del siguiente correo: <a href="mailto:helzy@elsetic.com">helzy@elsetic.com</a>
      </p>
      <a href='/'  className="thm-btn banner-one__btn">
        <span>Volver al inicio</span>
      </a>
    </section>
  );
};

export default PrivacyPolicyData;
