import React, {Component} from "react";
import axios from 'axios';
import BlockTitle from "./BlockTitle";
import ContactBgShape from "../assets/images/shapes/contact-bg-shape-1-1.png";
import ContactImage from "../assets/images/resources/contact-1-1.jpg";

const initialState = {
  name: '',
  email: '',
  subject: '',
  phone: '',
  message: '',
  sendButton: 'Enviar'
}

export default class Contact extends Component {
  constructor(props) {
    super(props)
    this.state = initialState;
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  };

  onChange(e) {
    if (e.target.id === 'name') {
      this.setState({ name: e.target.value });
    } else if (e.target.id === 'email') {
      this.setState({ email: e.target.value });
    } else if (e.target.id === 'subject') {
      this.setState({ subject: e.target.value });
    } else if (e.target.id === 'phone') {
      this.setState({ phone: e.target.value });
    } else if (e.target.id === 'message') {
      this.setState({ message: e.target.value});
    }
  }

  onSubmit(e) {
    this.changeButtonText('Enviando...')
    e.preventDefault();
    axios({
      method: 'post',
      url: 'https://us-central1-helzy-app.cloudfunctions.net/sendMoreInfoMessage',
      data: {
        name: this.state.name,
        email: this.state.email,
        subject: this.state.subject,
        phone: this.state.phone,
        message: this.state.message
      }
    })
    .then((response) => {
      this.changeButtonText('Enviado!')
      //setTimeout(() => this.changeButtonText(initialState.sendButton), 2000)
    })
    .catch((error) => {
      this.changeButtonText('Error!')
      setTimeout(() => this.changeButtonText(initialState.sendButton), 2000)
      console.log(error);
    });
  };

  changeButtonText(text) {
    this.setState({sendButton: text});
  }

  render() {
    const { sendButton } = this.state
    return (
        <section className="contact-one" id='contact'>
          <img
              src={ContactBgShape}
              className="contact-one__bg-shape-1"
              alt="awesome post"
          />
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <form className="contact-form-validated contact-one__form" onSubmit={this.onSubmit}>
                  <BlockTitle
                      textAlign="left"
                      paraText="Contáctanos!"
                      titleText={`¿Quieres participar en la fase Beta de Helzy? Únete!`}
                  />
                  <div className="row">
                    <div className="col-lg-6">
                      <input type="text" placeholder="Nombre" id="name" onChange={this.onChange}/>
                    </div>
                    <div className="col-lg-6">
                      <input type="text" placeholder="Email" id="email" onChange={this.onChange}/>
                    </div>
                    <div className="col-lg-6">
                      <input type="text" placeholder="Asunto" id="subject" onChange={this.onChange}/>
                    </div>
                    <div className="col-lg-6">
                      <input type="text" placeholder="Teléfono" id="phone" onChange={this.onChange}/>
                    </div>
                    <div className="col-lg-12">
                      <textarea placeholder="Mensaje" id="message" onChange={this.onChange}/>
                    </div>
                    <div className="col-lg-12 text-left">
                      <button type="submit" className="thm-btn contact-one__btn">
                        <span>{sendButton}</span>
                      </button>
                    </div>
                  </div>
                </form>
                <div className="result"></div>
              </div>
              <div
                  className="col-lg-5 d-flex wow fadeInRight"
                  data-wow-duration="1500ms"
              >
                <div className="my-auto">
                  <div className="contact-one__image">
                    <img src={ContactImage} alt="awesome post" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    );
  }
}
