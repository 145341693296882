import React from "react";

import {Container} from "react-bootstrap";
import BlockTitle from "./BlockTitle";

const Roadmap = () => {
    return (
        <section className="roadmap mt-5" id="roadmap">
            <Container>
                <BlockTitle
                    textAlign="center"
                    paraText="Roadmap"
                    titleText={`Esta es nuestra hoja de ruta`}
                />
                <div className="relative">
                    <div className="row mx-0 text-left lg:flex text-lg-center justify-center">
                        <div className="roadmap-item-past lg:text-center flex-1 col-lg">
                            <div>
                                <span><b>2021 Q3</b></span>
                                <h5>Concepto</h5>
                                <div>
                                    <ul className="text-left">
                                        <li><h6>Generación de conceptos</h6></li>
                                        <li><h6>Creación del equipo de desarrollo</h6></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="roadmap-item-past lg:text-center flex-1 col-lg">
                            <div>
                                <span><b>2021 Q4</b></span>
                                <h5>Diseño</h5>
                                <div>
                                    <ul className="text-left">
                                        <li><h6>Diseño de la aplicación</h6></li>
                                        <li><h6>Estrategia del MVP</h6></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="roadmap-item-actual lg:text-center flex-1 col-lg">
                            <div>
                                <span><b>2022 Q1</b></span>
                                <h5>Desarrollo</h5>
                                <div>
                                    <ul className="text-left">
                                        <li><h6>Aplicación Beta</h6></li>
                                        <li><h6>Mobile World Congress BCN</h6></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="roadmap-item-next lg:text-center flex-1 col-lg">
                            <div>
                                <span><b>2022 Q2</b></span>
                                <h5>Financiación</h5>
                                <div>
                                    <ul className="text-left">
                                        <li><h6>Sistema de suscripciones</h6></li>
                                        <li><h6>Alianzas estratégicas</h6></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="roadmap-item-next lg:text-center flex-1 col-lg">
                            <div>
                                <span><b>2022 Q3</b></span>
                                <h5>Evolución</h5>
                                <div>
                                    <ul className="text-left">
                                        <li><h6>Apertura pública</h6></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    )
};

export default Roadmap;
