import React from "react";
import BlockTitle from "./BlockTitle";

import TeamShape1 from "../assets/images/shapes/team-1-bg-1-1.png";
import TeamShape2 from "../assets/images/shapes/team-1-bg-1-2.png";
import TeamMember1 from "../assets/images/team/jorge-morata.jpg";
import TeamMember2 from "../assets/images/team/marta-crespo.jpg";
import TeamMember3 from "../assets/images/team/miguel-bazan.jpg";
import TeamMember4 from "../assets/images/team/sergio-moreno.jpg";
import TeamMember5 from "../assets/images/team/rosa-orriols.jpg";
import TeamMember6 from "../assets/images/team/luis-mazon.jpg";

const Team = () => {
  return (
    <section className="team-one" id="team">
      <img
        src={TeamShape1}
        className="team-one__bg-shape-1"
        alt="awesome post"
      />
      <img
        src={TeamShape2}
        className="team-one__bg-shape-2"
        alt="awesome post"
      />
      <div className="container">
        <BlockTitle
          textAlign="center"
          paraText="Equipo de Helzy"
          titleText={`Conoce los profesionales \n detrás de Helzy`}
        />
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="team-one__single">
              <div className="team-one__circle"></div>
              <div className="team-one__inner">
                <h3>Jorge Morata</h3>
                <p>Co-founder & CTO</p>
                <div className="team-one__image">
                  <img src={TeamMember1} alt="awesome post" />
                </div>
                <div className="team-one__social">
                  <a href="https://www.linkedin.com/in/jorgemoratatoribio/" target='_blank' rel="noreferrer">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="team-one__single">
              <div className="team-one__circle"></div>
              <div className="team-one__inner">
                <h3>Marta Crespo</h3>
                <p>Co-founder & CCO</p>
                <div className="team-one__image">
                  <img src={TeamMember2} alt="awesome post" />
                </div>
                <div className="team-one__social">
                  <a href="https://www.linkedin.com/in/marta-crespo-4916243a/" target='_blank' rel="noreferrer">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="team-one__single">
              <div className="team-one__circle"></div>
              <div className="team-one__inner">
                <h3>Miguel Bazán</h3>
                <p>Co-founder & CFO</p>
                <div className="team-one__image">
                  <img src={TeamMember3} alt="awesome post" />
                </div>
                <div className="team-one__social">
                  <a href="https://www.linkedin.com/in/miguel-angel-baz%C3%A1n-garc%C3%ADa-73731021/" target='_blank' rel="noreferrer">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="team-one__single">
              <div className="team-one__circle"></div>
              <div className="team-one__inner">
                <h3>Sergio Moreno</h3>
                <p>Co-Founder & Head of Design</p>
                <div className="team-one__image">
                  <img src={TeamMember4} alt="awesome post" />
                </div>
                <div className="team-one__social">
                  <a href="https://www.linkedin.com/in/sergio-moreno-pe%C3%B1alver-2a311847/" target='_blank' rel="noreferrer">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="team-one__single">
              <div className="team-one__circle"></div>
              <div className="team-one__inner">
                <h3>Rosa Orriols</h3>
                <p>Co-founder & CMO</p>
                <div className="team-one__image">
                  <img src={TeamMember5} alt="awesome post" />
                </div>
                <div className="team-one__social">
                  <a href="https://www.linkedin.com/in/rosa-maria-orriols-80677b29/" target='_blank' rel="noreferrer">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="team-one__single">
              <div className="team-one__circle"></div>
              <div className="team-one__inner">
                <h3>Luis Mazón</h3>
                <p>Co-founder & CEO</p>
                <div className="team-one__image">
                  <img src={TeamMember6} alt="awesome post" />
                </div>
                <div className="team-one__social">
                  <a href="https://www.linkedin.com/in/luis-mazon-cuadrado-04757b22/" target='_blank' rel="noreferrer">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
