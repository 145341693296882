import React from "react";

import {Link as ScrollLink} from "react-scroll";
import {Col, Row} from "react-bootstrap";
import iOSImage from "../assets/images/resources/doc-moc-ios-1.png";
import AndroidImage from "../assets/images/resources/doc-moc-android-1.png";
import BlogSingle from "./BlogSingle";
import BlogDetailsImage from "../assets/images/blog/blog-d-1-1.jpg";
import AuthorImage from "../assets/images/blog/author-1-1.jpg";
import CommentImage1 from "../assets/images/blog/comment-1-1.jpg";
import CommentImage2 from "../assets/images/blog/comment-1-2.jpg";

const DocumentationData = () => {
  return (
    <section className="documentation">
      <div>
        <Row>
          <Col lg={4} className="m-auto">
            <div className="pricing-one__single">
              <div className="pricing-one__circle"></div>
              <div className="pricing-one__inner">
                <p>iOS</p>
                <img src={iOSImage} width="300" alt="awesome post" />
                <a href=''>
                  <ScrollLink
                      className="thm-btn banner-one__btn"
                      activeClass="current"
                      to="downloadApp"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                  >
                    <span>Seleccionar</span>
                  </ScrollLink>
                </a>
              </div>
            </div>
          </Col>
          <Col lg={4} className="m-auto">
            <div className="pricing-one__single">
              <div className="pricing-one__circle"></div>
              <div className="pricing-one__inner">
                <p>Android</p>
                <img src={AndroidImage} width="300" alt="awesome post" />
                <a href=''>
                  <ScrollLink
                      className="thm-btn banner-one__btn"
                      activeClass="current"
                      to="downloadApp"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                  >
                    <span>Seleccionar</span>
                  </ScrollLink>
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div>
        <div className="blog-details__main">
          <div className="blog-details__content">
            <div className="blog-one__meta">
              <a href="#none">
                <i className="far fa-clock"></i> 27/01/2022
              </a>
            </div>
            <h3 id="iOS">Manual de usuario - iOS</h3>
            <p>
              En construcción!
            </p>
          </div>
          <div className="blog-details__meta">
            <div className="blog-details__tags">
            </div>
          </div>
        </div>
        <div className="blog-details__main">
          <div className="blog-details__content">
            <div className="blog-one__meta">
              <a href="#none">
                <i className="far fa-clock"></i> 27/01/2022
              </a>
            </div>
            <h3 id="iOS">Manual de usuario - Android</h3>
            <p>
              En construcción!
            </p>
          </div>
          <div className="blog-details__meta">
            <div className="blog-details__tags">
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DocumentationData;
