import React, { useState } from "react";
import ModalVideo from "react-modal-video";

import BannerShape from "../assets/images/shapes/banner-shape-1-1.png";
import BannerBG from "../assets/images/resources/banner-image-1-1.jpg";
import BannerMoc from "../assets/images/resources/banner-moc-1-1.png";
import {Link as ScrollLink} from "react-scroll";

const Banner = (props) => {
  const [open, setOpen] = useState({
    isOpen: false
  });
  const openModal = () => {
    setOpen({
      isOpen: true
    });
  };

  return (
    <section className="banner-one" id="home">
      <img
        src={BannerShape}
        className="banner-one__bg-shape-1"
        alt="awesome post"
      />
      <div
        className="banner-one__bg"
        style={{ backgroundImage: `url(${BannerBG})` }}
      ></div>
      <div className="container">

      </div>
      <div className="container">
        <ModalVideo
          channel="youtube"
          isOpen={open.isOpen}
          videoId="FUEtgtaParg"
          onClose={() => setOpen({ isOpen: false })}
        />
        <div
          onClick={openModal}
          onKeyDown={openModal}
          role="button"
          tabIndex="0"
          className="banner-one__video video-popup"
        >
          <i className="fa fa-play"></i>
        </div>
        <div className="banner-one__moc">
          <img
            src={BannerMoc}
            className="wow fadeInUp"
            data-wow-duration="1500ms"
            alt="awesome post"
          />
        </div>
        <div className="row">
          <div className="col-lg-7">
            <div className="banner-one__content">
              <div className="mc-form__response">
                <a href=''>
                  <ScrollLink
                      className={`thm-btn ${props.btnClass}`}
                      activeClass="current"
                      to="joinBeta"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                  >
                    <span>Participar en la Beta</span>
                  </ScrollLink>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-7">
            <div className="banner-one__content">
              <div className="mc-form__response"></div>
              <h3>
                Cuida de <br /> tus Familiares <br /> con Helzy
              </h3>
              <p>
                Cuando nuestros familiares están seguros {" "}
                <br /> la incertidumbre y las preocupaciones disminuyen.
              </p>
              <a href=''>
                <ScrollLink
                  className="thm-btn banner-one__btn"
                  activeClass="current"
                  to="features"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                <span>Características</span>
                </ScrollLink>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
