import React, { useState } from "react";
import { Link as ScrollLink } from "react-scroll";

const NavLinks = () => {
  const [dropdownStatus, setDropdownStatus] = useState(false);
  const handleDropdownStatus = () => {
    setDropdownStatus(!dropdownStatus);
  };
  return (
    <ul className="main-nav__navigation-box">
      <li>
        <ScrollLink
          activeClass="current"
          to="home"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Inicio
        </ScrollLink>
      </li>
      <li>
        <ScrollLink
          activeClass="current"
          to="features"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Características
        </ScrollLink>
      </li>
      <li>
        <ScrollLink
          activeClass="current"
          to="screens"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          App
        </ScrollLink>
      </li>
      <li>
        <ScrollLink
          activeClass="current"
          to="pricing"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Precio
        </ScrollLink>
      </li>
      <li>
        <ScrollLink
          activeClass="current"
          to="faqs"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          FAQs
        </ScrollLink>
      </li>
      <li>
          <ScrollLink
              activeClass="current"
              to="roadmap"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
          >
              Roadmap
          </ScrollLink>
      </li>
      <li>
        <ScrollLink
          activeClass="current"
          to="team"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Equipo
        </ScrollLink>
      </li>
      <li>
        <ScrollLink
          activeClass="current"
          to="contact"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Contacto
        </ScrollLink>
      </li>
    </ul>
  );
};

export default NavLinks;
