import React from "react";
import { Router } from "@reach/router"
import PrivacyPolicy from "./privacy-policy";
import MainPage from "../components/MainPage";
import TermsOfUse from "./terms-of-use";
import Documentation from "./documentation";


class HomePage extends React.Component {
    componentDidMount () {
        window.$sleek=[];
        window.SLEEK_PRODUCT_ID=547109236;
        (function(){
            let s=document.createElement("script");
            s.src = "https://client.sleekplan.com/sdk/e.js";
            s.async = 1;
            document.getElementsByTagName("head")[0].appendChild(s);
        })();
    };

    render () {
        return (
            <Router>
                <MainPage path="/" />
                <PrivacyPolicy path="/privacy-policy" />
                <TermsOfUse path="/terms-of-use" />
                <Documentation path="/documentation" />
            </Router>
        )
    }
}

export default HomePage;
