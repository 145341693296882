import React from "react";
import {Container} from "react-bootstrap";

import Cta3Shape1 from "../assets/images/shapes/cta-three-bg-1-1.png";
import Cta3Shape2 from "../assets/images/shapes/cta-three-bg-1-2.png";
import {Link as ScrollLink} from "react-scroll";
import LogoOnlyImage from "../assets/images/logo-1-0.png";

const JoinBeta = () => {
  return (
    <section className="cta-three" id='joinBeta'>
      <img src={Cta3Shape1} className="cta-three__bg-1" alt="awesome post" />
      <img src={Cta3Shape2} className="cta-three__bg-2" alt="awesome post" />
      <Container className="text-center">


        <div className="row text-center">
          <div className="col-lg-12">
            <a href="/">
              <img src={LogoOnlyImage} width="129" alt="Helzy" />
            </a>
          </div>
        </div>
        <h3 className="mt-5">
          Estamos en fase de pruebas!!
        </h3>
        <p>Participa en nuestra versión Beta y beneficiate!</p>
        <ul className="list-unstyled cta-one__list">
          <li>
            <i className="fa fa-check-circle"></i>
            Acceso prioritario a la aplicación de Helzy.
          </li>
          <li>
            <i className="fa fa-check-circle"></i>
            Descuento del 65% de la tarifa los 6 primeros meses.
          </li>
          <li>
            <i className="fa fa-check-circle"></i>
            Entrevistas con el equipo de Helzy para evaluar nuevas necesidades.
          </li>
        </ul>
        <a href=''>
          <ScrollLink
              className="thm-btn cta-one__btn"
              activeClass="current"
              to="contact"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
          >
            <span>Unirme!</span>
          </ScrollLink>
        </a>
      </Container>
    </section>
  );
};

export default JoinBeta;
