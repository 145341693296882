import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import BlockTitle from "./BlockTitle";
import {Link as ScrollLink} from "react-scroll";

const Pricing = (props) => {
  const [plan, setPlan] = useState(false);
  return (
    <section className="pricing-one" id="pricing">
      <Container>
        <BlockTitle
          textAlign="center"
          paraText="Precios"
          titleText={`Elige tu plan \n en función de tus necesidades`}
        />
        <ul
          className="list-inline text-center switch-toggler-list"
          id="switch-toggle-tab"
        >
          <li className={`month ${plan === false ? "active" : ""}`}>
            <a
              href="#none"
              onClick={(e) => {
                e.preventDefault();
                setPlan(false);
              }}
            >
              Mensual
            </a>
          </li>
          <li>
            <div
              role="button"
              tabIndex="0"
              onClick={(e) => {
                e.preventDefault();
                setPlan(!plan);
              }}
              onKeyDown={(e) => {
                e.preventDefault();
                setPlan(!plan);
              }}
              className={`switch ${plan === true ? "off" : "on"}`}
            >
              <span className="slider round"></span>
            </div>
          </li>
          <li className={`year ${plan === true ? "active" : ""}`}>
            <a
              href="#none"
              onClick={(e) => {
                e.preventDefault();
                setPlan(true);
              }}
            >
              Anual
            </a>
          </li>
        </ul>
        {plan === true ? (
          <div id="yearly">
            <Row>
              <Col lg={4} className="m-auto">
                <div className="pricing-one__single">
                  <div className="pricing-one__circle"></div>
                  <div className="pricing-one__inner">
                    <p>Plan Básico</p>
                    <h3>Gratis</h3>
                    <ul className="list-unstyled pricing-one__list">
                      <li>Posición GPS</li>
                      <li>Botón de Socorro</li>
                      <li>Alertas en tiempo real</li>
                      <li>Máximo 1 contacto</li>
                    </ul>
                    <a href=''>
                      <ScrollLink
                          className="thm-btn banner-one__btn"
                          activeClass="current"
                          to="downloadApp"
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                      >
                        <span>Descargar App</span>
                      </ScrollLink>
                    </a>
                    <span>Sin cargos ocultos!</span>
                  </div>
                </div>
              </Col>
              <Col lg={4} className="m-auto">
                <div className="pricing-one__single">
                  <div className="pricing-one__circle"></div>
                  <div className="pricing-one__inner">
                    <p>Plan Monitorización</p>
                    <h3>169€</h3>
                    <ul className="list-unstyled pricing-one__list">
                      <li>* Plan Básico incluido</li>
                      <li>Contactos ilimitados</li>
                      <li>Pulsera de Constantes Vitales</li>
                      <li>Parámetros vitales ajustables</li>
                    </ul>
                    <a href=''>
                      <ScrollLink
                          className="thm-btn pricing-one__btn"
                          activeClass="current"
                          to="contact"
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                      >
                        <span>Contáctanos!</span>
                      </ScrollLink>
                    </a>
                    <span>Sin cargos ocultos!</span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        ) : (
          <div id="month">
            <Row>
              <Col lg={4} className="m-auto">
                <div className="pricing-one__single">
                  <div className="pricing-one__circle"></div>
                  <div className="pricing-one__inner">
                    <p>Plan Básico</p>
                    <h3>Gratis</h3>
                    <ul className="list-unstyled pricing-one__list">
                      <li>Posición GPS</li>
                      <li>Botón de Socorro</li>
                      <li>Alertas en tiempo real</li>
                      <li>Máximo 1 contacto</li>
                    </ul>
                    <a href=''>
                      <ScrollLink
                          className="thm-btn banner-one__btn"
                          activeClass="current"
                          to="downloadApp"
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                      >
                        <span>Descargar App</span>
                      </ScrollLink>
                    </a>
                    <span>Sin cargos ocultos!</span>
                  </div>
                </div>
              </Col>
              <Col lg={4} className="m-auto">
                <div className="pricing-one__single">
                  <div className="pricing-one__circle"></div>
                  <div className="pricing-one__inner">
                    <p>Plan Monitorización</p>
                    <h3>15€</h3>
                    <ul className="list-unstyled pricing-one__list">
                      <li>* Plan Básico incluido</li>
                      <li>Contactos ilimitados</li>
                      <li>Pulsera de Constantes Vitales</li>
                      <li>Parámetros vitales ajustables</li>
                    </ul>
                    <a href=''>
                      <ScrollLink
                          className="thm-btn pricing-one__btn"
                          activeClass="current"
                          to="contact"
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                      >
                        <span>Contáctanos!</span>
                      </ScrollLink>
                    </a>
                    <span>Sin cargos ocultos!</span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </Container>

      <div className="container">
        <BlockTitle
            textAlign="center"
            titleText="Beneficiate de un 65% de descuento participando en la Beta de Helzy"
        />
        <div className="row text-center">
          <div className="col-lg-12">
            <div className="banner-one__content">
              <div className="mc-form__response">
                <a href=''>
                  <ScrollLink
                      className={`thm-btn ${props.btnClass}`}
                      activeClass="current"
                      to="joinBeta"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                  >
                    <span>Participar en la Beta</span>
                  </ScrollLink>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
