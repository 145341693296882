import React from "react";
import { Container } from "react-bootstrap";
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import PrivacyPolicyData from "../components/PrivacyPolicyData";

const PrivacyPolicy = () => (
  <Layout pageTitle="Helzy | Política de Privacidad">
    <PageHeader pageHeading="Helzy | Política de Privacidad" />

    <section className="blog-details">
      <Container>
        <PrivacyPolicyData />
      </Container>
    </section>

    <Footer />
  </Layout>
);

export default PrivacyPolicy;
