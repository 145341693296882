import React from "react";
import { Container } from "react-bootstrap";
import BlockTitle from "./BlockTitle";
import AccordionItem from "./AccordionItem";
import faqBG from "../assets/images/shapes/faq-bg-1-1.png";

const FAQ = (props) => {
  return (
    <section className="faq-one" id="faqs">
      <img src={faqBG} className="faq-one__bg-shape-1" alt="awesome post" />
      <Container>
        <BlockTitle
          textAlign="center"
          paraText="Preguntas Frecuentes"
          titleText={`Las preguntas que \n más nos hacen`}
        />
        <div className="accrodion-grp faq-accrodion">
          <AccordionItem
              title="¿Cómo puedo participar en la Beta de Helzy?"
              content="Muy fácil! Helzy esta en fase de pruebas actualmente y buscamos gente dispuesta
                      a participar para comprobar si la aplicación cumple con las espectativas de nuestros futuros
                       clientes. Por supuesto hay una recompensa para esas personas y es poder beneficiarse de un 65%
                       de descuento en nuestras tarifas!! Para ello, solo tienes que
                       contactar con nosotros a través del formulario que encontrarás al final de esta web."
              status={true}
          />
          <AccordionItem
            title="¿Es necesario que todos los familiares compremos el Plan Monitorización?"
            content="No. Únicamente con comprarla para la persona de la que quieres cuidar
                    es suficiente. El resto de familiares puede tener el Plan Básico y utilizar
                    la aplicación de forma gratuita."
            status={false}
          />
          <AccordionItem
            title="¿Es necesario agregar como contacto a la persona de la que quiero cuidar?"
            content="No. La persona a la que quieres cuidar, si que debe agregarte como contacto
                    para que puedas recibir las alertas, pero no es necesario que todos los familiares
                    lo añadáis explícitamente también."
            status={false}
          />
          <AccordionItem
            title="No veo la posición GPS de la persona, ¿Qué puedo hacer?"
            content="La primera vez que se instale la aplicación en el móvil de la persona que se quiere cuidar,
                     debes asegurarte que tiene activado el GPS y ha dado permisos a la aplicación de Helzy
                     para poder usarlo. Si no se le han dado permisos, el rastreo GPS no funcionará.
                     Si todo está bien configurado y no se obtiene la posición GPS puede deberse a que el
                     teléfono de la persona no tiene cobertura o está apagado."
            status={false}
          />
          <AccordionItem
              title="¿En qué idiomas está disponible Helzy?"
              content="Actualmente Helzy está disponible en Castellano, Catalán e Inglés. El idioma se puede
                       seleccionar en la pantalla de inicio la primera vez que se arranca la aplicación."
              status={false}
          />
        </div>
      </Container>
      <Container className='pt-5'>
        <BlockTitle
            textAlign="center"
            paraText="Preguntas Frecuentes sobre la pulsera Helzy"
            titleText={``}
        />
        <div className="accrodion-grp faq-accrodion">
          <AccordionItem
              title="¿Puedo utilizar la aplicación si no tengo una pulsera?"
              content="Por supuesto! La pulsera es una herramienta adicional (que se adquiere al contratar el Plan Monitorización).
                    Los familiares o contactos de emergencia, no es necesario que adquieran este plan, ni que compren la pulsera.
                    Puedes comprarla también para controlar tus constantes vitales, pero no es necesario para que otros te
                    incluyan a ti como contacto de emergencia."
              status={false}
          />
          <AccordionItem
              title="¿Sirve cualquier pulsera?"
              content="No. Únicamente sirve la que proporcionamos desde Helzy al contratar el Plan Monitorización."
              status={false}
          />
          <AccordionItem
              title="¿Qué mide exactamente la pulsera?"
              content="Actualmente Helzy puede medir 4 signos vitales (que se irán aumentando en el futursa versiones):
                       Pulso cardíaco, Temperatura corporal, Presión sanguinea y Oxígeno en sangre. Estas mediciones se
                       realizan conectando la aplicación a la pulsera a través del Bluetooth del teléfono."
              status={false}
          />
          <AccordionItem
              title="¿Cada cuanto se debe cargar la pulsera?"
              content="En condiciones normales, la batería de la pulsera tiene una duración de unos 2 días,
                    no obstante, recomendamos que se cargue cada noche si es posible para evitar apagados
                    involuntarios por falta de batería."
              status={false}
          />
        </div>
      </Container>
    </section>
  );
};

export default FAQ;
